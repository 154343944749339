import React from 'react';

import PageTemplate from './PageTemplate';
import { BlueSection, Heading, Paragraph } from '../components';

import ContactImg from '../assets/img/heroimage_contact_1.png';

import { useTranslation } from 'react-i18next';

export default function Contact(props) {
  const { t } = useTranslation();

  return (
    <PageTemplate page={t('contact')} mainBg="bg-unity-blue" hero={ContactImg} returnBg="bg-unity-blue">
      <BlueSection>
        <Heading className="margin-y-0">
          <span dangerouslySetInnerHTML={{ __html: t('contactUs') }}></span>
        </Heading>
        <br />
        <Paragraph className="usa-intro">
          <span dangerouslySetInnerHTML={{ __html: t('feedback') }}></span>
        </Paragraph>
      </BlueSection>
      <BlueSection>
        <Heading className="margin-y-0">
          <span dangerouslySetInnerHTML={{ __html: t('contactSectionTwo') }}></span>
        </Heading>
        <br />
        <Paragraph className="usa-intro">
          <span dangerouslySetInnerHTML={{ __html: t('contactSectionTwoText') }}></span>
        </Paragraph>
      </BlueSection>
      <BlueSection>
        <Heading className="margin-y-0">
          <span dangerouslySetInnerHTML={{ __html: t('contactSectionThree') }}></span>
        </Heading>
        <br />
        <Paragraph className="usa-intro">
          <span dangerouslySetInnerHTML={{ __html: t('contactSectionThreeText') }}></span>
        </Paragraph>
      </BlueSection>
    </PageTemplate>
  );
}


