import React from 'react';

import NYCLogo from '../assets/img/nyc_logomark.svg';

import {useTranslation} from 'react-i18next';

export default function AgencyGrid(props) {
  const {t} = useTranslation();

  return (
    <div className="usa-footer__secondary-section bg-base-dark text-white">
      <div className="grid-container bg-base-dark text-white">
        <div className="grid-row grid-gap">
          <div className="footer-text grid-col-12">
            <a className="white-link" href="https://forms.office.com/Pages/ResponsePage.aspx?id=mRfWc4TCIkCNQVTMTxkp76BEitoPVJJFin4TAr_nAVdUQ0I3UTI1TU1ITE9KN0U0MUlOV0swRFg2MS4u">{t('feedbackForm')}</a>
          </div>
          <div className="agency-grid footer-text grid-row grid-gap" style={{"alignItems": "flex-start"}}>
            <div className="mobile-lg:grid-col-5 desktop:grid-col-8" style={{marginTop: 20}}>
              <a href="http://nyc.gov/"><img src={NYCLogo} style={{width: "100%", maxWidth: 315}} alt="NYC.gov"/></a>
            </div>

            {/* <div className=" mobile-lg:grid-col-4 desktop:grid-col-4">
              <p>{t('collaboration')}</p>
              <a href="https://www1.nyc.gov/site/immigrants/index.page">
                <img src={MOIALogo} alt="" style={{maxWidth: 100}} />
              </a>
            </div> */}

            {/* <a className="mobile-lg:grid-col-4 desktop:grid-col-3"
              href="http://nyc.gov/fund">
              <img src={MayorsFundLogo} alt=""
                style={{maxWidth: 200, marginBottom: -2}} />
            </a> */}

            {/* <div className="mobile-lg:grid-col-4 desktop:grid-col-3">
              <p style={{whiteSpace: 'nowrap'}}>{t('websiteSupport')}</p>
            </div> */}
          </div>
          <div className="footer-text grid-col-12 margin-top-2">
            {t('allRightsReserved')}
            <br/>
            {t('tradeMark')}
          </div>
        </div>
      </div>
    </div>

  );
}
