import React from 'react';

import {
  Home, About, Contact, NotFound
} from './templates';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import GA from './utils/GoogleAnalytics';
import ScrollToTop from './utils/ScrollToTop';

export default (props) => (
  <Router baseroute="/">
    { GA.init() && <GA.RouteTracker/>}
    <ScrollToTop/>
    <Switch>
      <Route path="/" exact
        render={(props) => (
          <Home/>
        )}
      />

      <Route path="/questions" exact
        render={(props) => (
          <About/>
        )}
      />

      <Route path="/resources" exact
        render={(props) => (
          <Contact/>
        )}
      />
      
      <Route component={NotFound} />

    </Switch>
  </Router>
);
