import React from 'react';

import { GovBanner, Header, Footer, BlueSection, Heading, Paragraph } from '../components';
import Button from '../components/Button';
import {logEvent} from '../utils/GoogleAnalytics';

import hero from '../assets/img/ebb_404.png';

import { useTranslation } from 'react-i18next';

export default function NotFound(props) {
  const { t } = useTranslation();

  return (
    <div>
      <GovBanner />
      <Header currentPage={t('404')} />

      <main id="main-content" className="bg-unity-blue">
        <div className="usa-hero" aria-label="Introduction"
          style={{ backgroundImage: `url('${hero}')` }}>
          <div className="grid-container">
            <div className="usa-hero__callout">
              <h1 className="usa-hero__heading">
                <span className="usa-hero__heading--alt
            text-barlow-logo text-unity-blue">
                  {t('404')}<br />
                  {/* <span className="text-accent-cool">
                {t('inclusivityGuide')}
              </span> */}
                </span>
              </h1>
              <Button link="/"
                onClick={() => logEvent("Button Click", "User clicked downloadReport")}>
                {t('404LinkText')}
              </Button>
            </div>
          </div>
        </div>

        <BlueSection>
          <Heading className="margin-y-0">
            <span dangerouslySetInnerHTML={{ __html: t('404NotFound') }}></span>
          </Heading>
          <br />
          <Paragraph className="usa-intro">
            <span dangerouslySetInnerHTML={{ __html: t('404Text') }}></span>
          </Paragraph>
        </BlueSection>
      </main>

      <Footer returnBg="bg-unity-blue" />
    </div>
  );
}
