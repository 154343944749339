import React from 'react';
import NYC from '../assets/img/nyc.svg';

import { useTranslation } from 'react-i18next';
import i18n from '../i18n/config';
import { LANGUAGES } from '../i18n/config';

import { SkipNavLink } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";

import { logEvent } from '../utils/GoogleAnalytics';

export default function GovBanner() {
  const { t } = useTranslation();

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="govbanner">
      <SkipNavLink>{t('skipContent')}</SkipNavLink>
      <section className="usa-banner bg-primary-darker banner-adjustment"
        aria-label="Official government website">
        <div className="usa-accordion">
          <header className="usa-banner__header" style={{ paddingBottom: 15, paddingTop: 0 }}>
            <div className="usa-banner__inner">
              <div className="grid-col-fill tablet:grid-col-auto">
                <p className="usa-banner__header-text text-white">
                  <a href="https://www1.nyc.gov/" className="usa-banner__nyc-logo">
                    <img src={NYC} alt="NYC City Logo" style={{ paddingRight: 28 }} />
                  </a>
                  <span className="display-none desktop:display-inline">{t('topNav')}</span>
                </p>
              </div>
              <div className='grid-col-fill'></div>
              {/* Note, this langauge doesn't translate, should find another more universal solution */}
              <span class="sr-only">Select your language</span>
              <ul className='usa-list usa-list--unstyled grid-row'>
                {
                  LANGUAGES.map((item) => (
                    <li key={item.lang}><a href='#' className='grid-col text-white' style={{ marginRight: 5 }}
                      onClick={() => {
                        setLanguage(item.lang);
                        logEvent("Translated", `User translated to ${item.lang}`)
                      }}
                    >{item.name}</a></li>
                  ))
                }
              </ul>
            </div>
          </header>
        </div>
      </section>
    </div>
  );
}
