import React, { lazy } from 'react';
import { logEvent } from '../utils/GoogleAnalytics';

import PageTemplate from './PageTemplate';
import {
  YellowSection, Grid, BlueSection,
  Button, Heading, Paragraph, GridSquare
} from '../components';

import HomeImg from '../assets/img/heroimage_home_1.png';
import CircleImg1 from '../assets/img/icon_monthly.svg';
import CircleImg3 from '../assets/img/icon_ontimedevice.svg';

import { useTranslation, exists } from 'react-i18next';

export default function Home(props) {
  const { t } = useTranslation();

  return (
    <PageTemplate page={t('home')} mainBg="bg-white"
      hero={HomeImg} returnBg="bg-white">
      {/* start alert banner. TODO: make this a component */}
      {/* note that an react-i18next.exists doesn't exist so using this silly check for existance */}
      {t('alertText') !== 'alertText' &&
        <div className="grid-container">
          <div class="usa-alert usa-alert--info usa-alert--no-icon">
            <div class="usa-alert__body">
              <span dangerouslySetInnerHTML={{ __html: t('alertText') }}></span>
            </div>
          </div>
        </div>
      }
      {/* end alert banner */}
      <YellowSection>
        <div className="tablet:grid-col-4">
          <Heading className="margin-top-0 tablet:margin-bottom-0">
            <span dangerouslySetInnerHTML={{ __html: t('introText') }}></span>
          </Heading>
        </div>

        <Paragraph className="tablet:grid-col-8">
          <span dangerouslySetInnerHTML={{ __html: t('introTextDescription') }}></span>
        </Paragraph>
      </YellowSection>

      <Grid>
        <div className="usa-graphic-list__row grid-row grid-gap">
          <GridSquare img={CircleImg1} altText={t('conceptsAlt')}
            heading={t('conceptsHeading')}>
            <span dangerouslySetInnerHTML={{ __html: t('conceptsAndVocab') }}></span>
          </GridSquare>

          <GridSquare img={CircleImg3} altText={t('safeSpaceAlt')}
            heading={t('safeSpaceHeading')}>
            <span dangerouslySetInnerHTML={{ __html: t('safeSpace') }}></span>
          </GridSquare>
        </div>
      </Grid>

      <BlueSection>
        <Heading className="margin-y-0">
          <span dangerouslySetInnerHTML={{ __html: t('fullReport') }}></span>
        </Heading>
        <br />
        <Paragraph className="usa-intro">
          <span dangerouslySetInnerHTML={{ __html: t('fullReportDescription') }}></span>
        </Paragraph>
      </BlueSection>

    </PageTemplate>
  );
}
